/* global Component */
class AvatarPersonsListComponent extends Component {

    static name() {
        return "AvatarPersonsListComponent";
    }

    static componentName() {
        return "AvatarPersonsListComponent";
    }

    getProps() {
        return ['data', 'index', 'column'];
    }

    getComputed() {
        return  {
            url:function () {
                if(this.data.Avatar)
                    return this.data.Avatar;
                else return "../static/ecommerce/img/user.png";
            },
        };
    }

    getTemplate() {
        return `<img key='avatar ' :src="url" alt="" width="45" height="45" class="avatar img-fluid rounded-circle customer-image responsive-img" style="background: #6a6a6a">`;
    }
}

AvatarPersonsListComponent.registerComponent();

